































































































import Vue from 'vue'
import SearchIcon from '@/assets/icons/search.svg'
import VTable from '@/components/VTable/VTable.vue'
import VButton from '@/components/VButton.vue'
import ToasSucces from '@/components/ToasSucces.vue'
import InvitationModal from './components/InvitationModal.vue'
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { EMPTY_TABLE_MESSAGE } from '@/interfaces'
import VTablePagination from '@/components/VTable/VTablePagination.vue'

export default Vue.extend({
  components:{
    SearchIcon,
    VTable,
    VButton,
    InvitationModal,
    ToasSucces,
    VTablePagination
  },

  data(this: any){
    return {
      search: '',
      appendParams: {},
      emptyDataMessage: EMPTY_TABLE_MESSAGE.default,
      columns: [
        {
          name: 'email',
          title: 'Compte',
          transform({firstName, lastName, email}: any){
            return `
            <div class="text-black">${firstName} ${lastName} </div>
            <div class="show-data hidden">(${email}) </div>
            `
          }
        },
        {
          name: 'telephone', title: 'Téléphone', transform({telephone}: any){ return `${telephone ? '229 ' + telephone: ''}`}
        },
        {
          name: 'invited_by.firstName',
          title: 'Invité par',
          transform:({invited_by,createdAt}: any) =>{
            if (invited_by) {
              return `
                <div class="text-black">${invited_by.firstName} </div>
                <div class="show-data hidden" >(Le ${this.formateDate(createdAt)}) </div>
              `
            }
            return ''
          }
        },
        {
          name: 'roles',
          title: 'Role',
          width: '18%',
          transform({roles}: any){
            const rolesNames: string[] = roles.map((role: any) => {
              return role.name
            })
            return rolesNames.toString()
          }
        },
      ],

      columnsMobile: [
        {
          name: 'email',
          title: 'Compte',
          transform({firstName, lastName, email}: any){
            return `
            <div class="text-black font-bold">${firstName} ${lastName} </div>
            <div class="show-data hidden">(${email}) </div>
            `
          }
        },
      ],
      admins: [],
      modalActionComponent: {
        name: '',
        props:{}
      },
      modalSize: 'md',
      showModalAction: false,
      toast: false,
      sendTo: ''
    }
  },

  methods:{

    onChangePage(page: string | number) {
      console.log('change-page', page);

      //@ts-ignore
      this.$refs.table.changePage(page)
    },

    formateDate(date: any){
      if (!date) {
        return ''
        }
      const dt = DateTime.fromISO(date)
      return dt.toLocaleString(DateTime.DATE_MED)
    },


    onPaginationData(paginationData: any){
      console.log('Operation paginationData: ====>', paginationData);
      //@ts-ignore
      this.$refs.tablePagination.setPagination(paginationData)
    },

    loadAdminOnApiMode(this: any,{options}: any){
      const params = options['params']
      return new Promise((resolve, reject) => {
        this.$store.dispatch('admins/getAllAdmin', params)
        .then((response: any) => {
          console.log('admins ', response);

          resolve({data: response})
        })
        .catch((error: any) => {
          console.log('error ', error);
          reject(error)
        })
      })
    },


    onAddUserClick(this: any){
      //
      this.modalActionComponent = {
        name: 'InvitationModal',
        props:{
          roles: this.roles,
          admin: this.auth
        }
      }
      this.showModalAction = true;
    },



    onSearch(this: any, search: string){
      //
      this.appendParams.search = search;
      if (search.length > 0) {
        this.emptyDataMessage = EMPTY_TABLE_MESSAGE.search
      }else {
        this.emptyDataMessage = EMPTY_TABLE_MESSAGE.default
      }
      this.refreshTables()
    },

    fetchRoles(): void{
      this.$store.dispatch('roles/getAdminRole')
    },

    refreshTables(){
      //@ts-ignore
      this.$refs.table.refresh();
      //@ts-ignore
      this.$refs.mobileTable.refresh()
    },

    showToast(this: any, to: string){
      this.sendTo = to
      this.showModalAction = false
      this.toast = true;
      setTimeout(() => {
        this.toast = false
      }, 4000);
    }

  },

  computed: {
    ...mapGetters('roles',['roles']),
    ...mapGetters('auth',['auth'])
  },

  mounted(this: any){
    console.log('roles', this.roles);

    this.fetchRoles()
    // this.loadAdminOnApiMode({})
    // .then((response: any) => {
    //   this.admins = response.data
    // })
    // .catch((error: any) => {
    //   console.log('error ', error);

    // })
  }
})
